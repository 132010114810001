import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Http } from '@angular/http';
import { Headers, RequestOptions } from '@angular/http';
const API_URL = environment.baseUrl;
const freshdeskUrl = 'https://mywealthjunctionhelp.freshdesk.com/api/v2/tickets';
const clientId = "angular-client";
const clientSecret = "angular-secret";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  httpOptions: any;
  token: string;
  detailedAUMData: any;
  constructor(private http: HttpClient, private httpClient: Http) { }

  // LOGIN
  getAccessToken(data): Observable<any> {
    let params = new URLSearchParams();
    params.append('username', data.username);
    params.append('password', data.password);
    params.append('grant_type', data.grant_type);
    let headers = new Headers({ 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8', 'Authorization': 'Basic ' + btoa('angular-client' + ":" + 'angular-secret') });
    let options = new RequestOptions({ headers: headers });
    return this.httpClient.post(API_URL + '/oauth/token', params.toString(), options)

  }

  postRefreshToken(): Observable<any> {
    let refresh_token = sessionStorage.getItem('refresh_token');
    let params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refresh_token);
    console.log("params=", params);
    let headers = new Headers({ 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8', 'Authorization': 'Basic ' + btoa('angular-client' + ":" + 'angular-secret') });
    let options = new RequestOptions({ headers: headers });
    return this.httpClient.post(API_URL + '/oauth/token', params.toString(), options)
  }

  // AFTER LOGIN GET OBJECT
  getSecureData(name: string, token: string): Observable<any> {
    let headers = new Headers({
      "Content-Type": "application/json",
      'Authorization': 'Bearer ' + token,
    });
    this.token = token;
    let options = new RequestOptions({ headers: headers });
    this.setHeaders();
    return this.httpClient.get(API_URL + '/backend/private/user-information', options);

  }

  getDeactiveUsers(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/deactivated-user-list', this.httpOptions);
  }

  getPlanExpiredUsers(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/all/expiredUsers', this.httpOptions);
  }

  getBSEDetails(userId): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/update-bse-details-by-id/' + userId, this.httpOptions);
  }

  updateBSEDetails(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/edit-bse-details-by-id', data, this.httpOptions);
  }
  
  getDeactiveaAdvisor(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/all-deactivated-users/advisor', this.httpOptions);
  }
  getDeactivePartners(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/all-deactivated-users/partner', this.httpOptions);
  }
  getDeactiveStaff(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/all-deactivated-users/others', this.httpOptions);
  }

  getDeactiveOtherStaff(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/get-deactivated-otherUser', this.httpOptions);
  }

  getAllRoles(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/role', this.httpOptions);
  }

  getUserInfoByEmailId(email): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/user/private/get-user-information', this.httpOptions);
  }

  // getPaidUsers(): Observable<any> {
  //   this.setHeaders();
  //   return this.http.get(API_URL + '/user/private/user-list', this.httpOptions);
  // }
  // getPaidUsers(page,size,sort?,order?): Observable<any> {
  //   this.setHeaders();
  //   return this.http.get(`${API_URL}/user/private/user-list?page=${page}&size=${size}&sort&order`, this.httpOptions);
  // }
  getPaidUsers(data,page,size,sort?,order?): Observable<any> {
    this.setHeaders();
    return this.http.post(`${API_URL}/backend/private/user-list?page=${page}&size=${size}&sort&order`, data ,this.httpOptions);
  }

  getAllUsers(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/all-user', this.httpOptions);
  }

  getAllAdvisor(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/users/advisor', this.httpOptions);
  }

  getAllPartner(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/users/partner', this.httpOptions);
  }

  getAllBackendUserList(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/getOtherUser', this.httpOptions);
  }

  saveAllUsers(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/user', data, this.httpOptions);
  }

  updateAllUsers(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/user', data, this.httpOptions);
  }

  getUserById(data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/user/' + data, this.httpOptions);
  }

  deleteUserById(data): Observable<any> {
    this.setHeaders();
    return this.http.delete(API_URL + '/backend/private/delete-user/' + data, this.httpOptions);
  }

  deleteBackendUsers(data): Observable<any> {
    this.setHeaders();
    return this.http.delete(API_URL + '/backend/private/user/' + data, this.httpOptions);
  }

  saveRolesAndPrivilages(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/role/', data, this.httpOptions);
  }

  getRolesAndPrivilagesByName(data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/roles?roleId=' + data, this.httpOptions);
  }

  getFreeUserlist(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/user-list-new', this.httpOptions);
  }

  saveAssignAdvisor(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/assign-advisor', data, this.httpOptions);
  }

  getUserAssignToEachAdvisor(data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/assign-to-me/' + data, this.httpOptions);
  }

  deAssignUserAndAdvisors(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/deassign-advisor', data, this.httpOptions);
  }

  // change password
  changePassword(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/change-password', data, this.httpOptions);
  }

  changeBSEPassword(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/user/bse/update-superAdmin', data, this.httpOptions);
  }


  changeMorningStarPassword(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/user/bse/update-morningStar', data, this.httpOptions);
  }

  updatepaidUser(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/update-user', data, this.httpOptions);
  }

  saveConsultaion(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/save-consultation', data, this.httpOptions);
  }

  getAllConsultaion(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/all-consultation', this.httpOptions);
  }
  // call logs
  getAllCallLogs(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/getExotelCallLog', data, this.httpOptions);
  }
  // call customer exotel
  callCustomer(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/connect-exotel-call', data, this.httpOptions);
  }

  // get object on phone number (exotel)
  // call logs
  getDataOnPhoneNumber(phoneNumber): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/get-user-by-phone-number?phoneNumber=' + phoneNumber, this.httpOptions);
  }

  getAllconsultationForAdvisor(data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/all-consultation/' + data, this.httpOptions);
  }

  getAllPaymentList(month, year, data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/get-all-payments-topart?month=' + month + '&year=' + year + '&partnerCode=' + data, this.httpOptions)
  }

  saveAllPayments(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/savePaymentHistory', data, this.httpOptions)
  }

  viewAllPayments(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/viewAllPayment', this.httpOptions)
  }

  getAllUserForPartner(data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/get-user-registered/' + data, this.httpOptions)
  }

  getAllSubscribeData(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/subscriptions', this.httpOptions)
  }

  getSubscriptionEndingData(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/ending-subscriptions', this.httpOptions)
  }

  getAllOffers(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/offer/view', this.httpOptions)
  }

  deleteoffers(id): Observable<any> {
    this.setHeaders();
    return this.http.delete(API_URL + '/backend/private/offer/delete?id=' + id, this.httpOptions)
  }

  submitEditedoffers(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/enable-disable-offers', data, this.httpOptions)
  }

  saveOffers(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/offer/save', data, this.httpOptions)
  }

  updateOffers(data): Observable<any> {
    this.setHeaders();
    return this.http.put(API_URL + '/backend/private/offer/update', data, this.httpOptions)
  }

  public getDashboardDataForAdmin(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/dashboard', this.httpOptions)
  }

  public getDashboardDataForpartner(data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/partner/dashboard/' + data, this.httpOptions)
  }

  public getDashboardForAdvisor(data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/advisor/dashboard/' + data, this.httpOptions)
  }

  public getFundList(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/user/bse/fund-house-matserdata-list', this.httpOptions)
  }

  public getFundListLive(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/user/bse/fund-list-live', data, this.httpOptions)
  }

  public changeSubCategoryForFundInMWJRecommended(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/user/bse/update-fund-byid', data, this.httpOptions)
  }

  public mwjRecommended(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/user/bse/mwj-recommended', data, this.httpOptions)
  }

  public getAllAsumption(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/assumptions/fetchall', this.httpOptions)
  }

  public saveAllAsumption(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/assumptions/update', data, this.httpOptions)
  }

  public sendInvoice(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/send-invoice', data, this.httpOptions)
  }

  // /backend/private/all-consultation
  // 
  getNewUserList(): Observable<any> {
    this.setHeaders();
    let params = new URLSearchParams();
    params.append('numOfDays', '7');
    return this.http.get(API_URL + '/backend/private/user-list-weekly?' + params.toString(), this.httpOptions);
  }

  getNewUserListForPartner(data): Observable<any> {
    this.setHeaders();
    let params = new URLSearchParams();
    params.append('numOfDays', '7');
    params.append('partnerCode', data);
    return this.http.get(API_URL + '/backend/private/user-list-weekly-by-partner?' + params.toString(), this.httpOptions);
  }

  // forgot password
  forgotPassword(data): Observable<any> {
    const body = new HttpParams()
      .set(`userId`, data)
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(API_URL + '/backend/public/forgot-password', body.toString(), { headers, observe: 'response' });
  }


  // reset password
  resetPassword(data): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.http.post(API_URL + '/backend/public/reset', data, httpOptions);
  }

  // Logout api
  deleteToken(userId): Observable<any> {
    let token = sessionStorage.getItem('access_token');
    let headers = new Headers({
      'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
      'Authorization': 'Basic ' + btoa('angular-client' + ":" + 'angular-secret'),
      'AUTH-TOKEN': 'Bearer ' + token,
    });
    let options = new RequestOptions({ headers: headers });
    sessionStorage.clear();
    return this.httpClient.delete(API_URL + '/oauth/token?id='+ userId, options)
  }

  addNewUser(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/user-registration', data, this.httpOptions);
  }



  getUserLogs(uccCode): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/get-user-audit-log/' + uccCode, this.httpOptions);
  }


  // save profile picture
  postProfilePicture(data, token): Observable<any> {
    let formData = new FormData();
    formData.append('profileImage', data.profileImage);
    // formData.append('userId', data.email);
    let hdrs = new HttpHeaders({ 'Authorization': 'Bearer ' + token, });
    const req = new HttpRequest('POST', API_URL + '/backend/private/backend-profile-image', formData, {
      headers: hdrs,
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

  uploadCsv(data, token): Observable<any> {
    let formData = new FormData();
    formData.append('CSVFile', data.CSVFile);
    let hdrs = new HttpHeaders({ 'Authorization': 'Bearer ' + token, });
    const req = new HttpRequest('POST', API_URL + '/user/private/uploadCSV?partnerCode=' + data.partnerCode, formData, {
      headers: hdrs,
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }


  // set headers after login for auth
  setHeaders() {
    this.token = sessionStorage.getItem('access_token');
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.token })
    };
  }

  public isAuthenticated(): boolean {
    const token = sessionStorage.getItem('access_token');
    if (token) {
      return true;
    } else {
      return false;
    }
  }

  getFileForEmailExtractor(data): Observable<any> {
    const token = sessionStorage.getItem('access_token');
    let httpOps = {
      headers: new Headers({ 'Authorization': 'Bearer ' + token })
    };
    return this.httpClient.post(API_URL + '/backend/private/email-extractor', data, httpOps);
  }

  public getInvoiceForSubscribed(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/user/private/download-paid-user-invoice', data, this.httpOptions);
  }

  // get paid user report data
  getPaidUserReportData(email): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/user/private/generate-user-report/' + email, this.httpOptions);
  }

  // download paid user report
  public downloadReports(email, data): Observable<any> {
    let token = sessionStorage.getItem('access_token');
    this.setHeaders();
    // return this._httpClient.post(API_URL + '/user/private/download-paid-user-report/'+ email, data, this.httpOptions);
    let httpOps = {
      headers: new Headers({ 'Authorization': 'Bearer ' + token })
    };
    return this.httpClient.post(API_URL + '/user/private/download-paid-user-report/' + email, data, httpOps);
  }


  public activateUsers(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/enable-deactivated-users', data, this.httpOptions);
  }

  public sendSMStoPlanExpiredUsers(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/sendSMS', data, this.httpOptions);
  }

  // planExpiredUsersSmsSentList(): Observable<any> {
  //   this.setHeaders();
  //   return this.http.get(API_URL + '/backend/private/smsHistory' , this.httpOptions);
  // }


  public activateRoles(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/enable-deactivated-backend-users', data, this.httpOptions);
  }

  public uploadXsipOrder(data, token): Observable<any> {
    // this.setHeaders();
    let formData = new FormData();
    formData.append('file', data.file);
    let hdrs = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    const req = new HttpRequest('POST', API_URL + '/user/bse/upload-bse-child-orders', formData, {
      headers: hdrs,
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
    // return this.http.post(API_URL + '/user/bse/upload-bse-child-orders', data, this.httpOptions);
  }

  // createTheTicket(data, token): Observable<any> {
  //   this.setHeaders();
  //   let newData = {
  //     apiEndPoint: freshdeskUrl,
  //     apiToken: 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY',
  //     description: data.description,
  //     subject: data.subject,
  //     email: data.email,
  //     priority: data.priority,
  //     status: data.status,
  //     source: data.source,
  //     fileList: data.attachments,
  //     custom_fields: {
  //       cf_ticket_no: 1
  //     }
  //   }
  //   let custom_fields = {
  //     cf_ticket_no: 1
  //   }
  //   let formData = new FormData();
  //   formData.append('description', data.description);
  //   formData.append('subject', data.subject);
  //   formData.append('email', data.email);
  //   formData.append('priority', data.priority);
  //   formData.append('status', data.status);
  //   formData.append('source', data.source);
  //   formData.append('attachments[]', data.attachments);
  //   formData.append('custom_fields', JSON.stringify({"custom_fields":{"cf_ticket_no":"1"}}));
  //   let hdrs = new HttpHeaders({ 'Authorization': 'Basic ' + 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY' });
  //   const req = new HttpRequest('POST', freshdeskUrl, formData, {
  //     headers: hdrs,
  //     reportProgress: true,
  //     responseType: 'text'
  //   });
  //   return this.http.request(req);
  // }

  // Get all tickets (FreshDesk)

  // Crete A ticket (FreshDesk)
  createTheTicket(data): Observable<any> {
    let hdrs = new Headers({ 'Content-type': 'application/json', 'Authorization': 'Basic ' + 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY' });
    let options = new RequestOptions({ headers: hdrs });
    return this.httpClient.post(freshdeskUrl, data, options);
  }

  getTheTickets(perPage, page): Observable<any> {
    let hdrs = new Headers({ 'Content-type': 'application/json', 'Authorization': 'Basic ' + 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY' });
    let options = new RequestOptions({ headers: hdrs });
    return this.httpClient.get(freshdeskUrl + '?per_page=' + perPage + '&page=' + page, options);
  }

  // Delete the particular ticket (FreshDesk)
  deleteParticularTicket(id): Observable<any> {
    let hdrs = new Headers({ 'Content-type': 'application/json', 'Authorization': 'Basic ' + 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY' });
    let options = new RequestOptions({ headers: hdrs });
    return this.httpClient.delete(freshdeskUrl + '/' + id, options);
  }

  // Delete the particular ticket (FreshDesk)
  viewParticularTicket(id): Observable<any> {
    let hdrs = new Headers({ 'Content-type': 'application/json', 'Authorization': 'Basic ' + 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY' });
    let options = new RequestOptions({ headers: hdrs });
    return this.httpClient.get(freshdeskUrl + '/' + id + '?include=requester, conversations', options);
  }

  // Add Reply to the particular ticket (FreshDesk)
  addReplyToParticularTicket(id, data): Observable<any> {
    let hdrs = new Headers({ 'Content-type': 'application/json', 'Authorization': 'Basic ' + 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY' });
    let options = new RequestOptions({ headers: hdrs });
    return this.httpClient.post(freshdeskUrl + '/' + id + '/reply', data, options);
  }

  // Updating  the particular ticket (FreshDesk)
  updatingParticularTicket(id, data): Observable<any> {
    let hdrs = new Headers({ 'Content-type': 'application/json', 'Authorization': 'Basic ' + 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY' });
    let options = new RequestOptions({ headers: hdrs });
    return this.httpClient.put(freshdeskUrl + '/' + id, data, options);
  }

  // Sorting tickets (FreshDesk)
  sortTickets(sortBy, perPage, page): Observable<any> {
    let hdrs = new Headers({ 'Content-type': 'application/json', 'Authorization': 'Basic ' + 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY' });
    let options = new RequestOptions({ headers: hdrs });
    return this.httpClient.get(freshdeskUrl + '?order_by=' + sortBy + '&order_type=desc' + '&per_page=' + perPage + '&page=' + page, options);
  }

  // Updating  the particular ticket (FreshDesk)
  searchTicketsBy(searchQuery): Observable<any> {
    let hdrs = new Headers({ 'Content-type': 'application/json', 'Authorization': 'Basic ' + 'NWxUTXl0YmZoRjNwZmN5VTJ6NDpY' });
    // let params = new HttpParams().set('query', searchQuery)
    let options = new RequestOptions({ headers: hdrs });
    let encoded = encodeURI(searchQuery);
    console.log(encoded, "encoded");
    if (searchQuery == "status:2 OR status:3") {
      return this.httpClient.get('https://mywealthjunctionhelp.freshdesk.com/api/v2/search/tickets?query="status:2 OR status:3"', options);
    } else if (searchQuery == "status:4 OR status:5") {
      return this.httpClient.get('https://mywealthjunctionhelp.freshdesk.com/api/v2/search/tickets?query="status:4 OR status:5"', options);
    }
  }

  downloadUserRiskProfile(email): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/download-user-risk-profile/' + email, this.httpOptions);
  }

  downloadAOFDocument(id): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/download-aof/' + id, this.httpOptions);
  }

  downloadFinancialPlanningProofDocument(email): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/download-proof-doc/' + email, this.httpOptions);
  }

  // For referral Logic 
  getReferralLogic(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/referral-offer/fetchall', this.httpOptions);
  }

  filterForReferralCode(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/referral-list-sort', data, this.httpOptions);
  }

  updateReferralOffer(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/referral-offer/update', data, this.httpOptions);
  }

  setDataForDetailedAUM(data) {
    this.detailedAUMData = data;
  }

  getDataForDetailedAUM() {
    return this.detailedAUMData;
  }

  getUsersForCVLKRAList(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/CVL-User', this.httpOptions);
  }

  getAllCvlKraDocumentsInZipForDownload(email): Observable<any> {
    let token = sessionStorage.getItem('access_token');
    this.setHeaders();
    let httpOps = {
      headers: new Headers({ 'Authorization': 'Bearer ' + token })
    };
    return this.httpClient.get(API_URL + '/backend/private/CVL-documents/' + email, httpOps);
  }

  getAllCvlKraFormForDownload(email): Observable<any> {
    let token = sessionStorage.getItem('access_token');
    this.setHeaders();
    let httpOps = {
      headers: new Headers({ 'Authorization': 'Bearer ' + token })
    };
    return this.httpClient.get(API_URL + '/backend/private/CVL-form-load/' + email, httpOps);
  }

  changeEKYCStatus(eKycStatusObj): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/change-cvl-admin-status', eKycStatusObj, this.httpOptions);
  }

  getAllBSETransactions(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/fetchall-bse-transaction', data, this.httpOptions);
  }

  getAllTransactionFileForDownload(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download-bse-transactions', data, this.httpOptions);
  }

  setTheIPVDate(data, token): Observable<any> {
    this.token = token;
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token })
    };
    return this.http.post(API_URL + '/backend/private/ipv-date', data, this.httpOptions);
  }
  // accept or reject POI documnet
  updatePOIStatus(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/accept-proof', data, this.httpOptions);
  }

  getUserLogsDataForDownload(userId): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/download-user-logs-by-id/' + userId, this.httpOptions);
  }

  aofAndBankProofApproved(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/aof-status-update', data, this.httpOptions);
  }

  getFinxpertPlanDetails(): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/qwikcilver/getall-qwik-sub', this.httpOptions);
  }

  postFinxpertDiscont(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/qwikcilver/save-update/qwikCilver/sub' , data, this.httpOptions);
  }

  getAllFinxpertTransaction(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/qwikcilver/getall-success-qwik-transactions', data, this.httpOptions);
  }

  updatePhysicalComment(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/update-physical-qwikcilver-comment', data, this.httpOptions);
  }

  updateAllTrnxComment(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/transaction-status-comment', data, this.httpOptions);
  }

  getAllManualKycUsers(data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/ekyc-users?status='+data, this.httpOptions);
  }

  getCardDetailsforHistory(data): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/qwikcilver/getCardDetails/'+data, this.httpOptions);
  }

  updateManualKycStatus(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/ekyc-change-status-user', data, this.httpOptions);
  }

  getAllMWJTranx(data): Observable<any>{
    this.setHeaders();
    let params = new URLSearchParams();
    params.append('page', data.page);
    params.append('size', data.size);
    params.append('sort', data.sort);
    params.append('order', data.order);
    params.append('paymenttype', data.paymenttype);
    params.append('status', data.status);
    return this.http.get(API_URL + '/backend/private/trx/order?'+ params.toString(), this.httpOptions);
  }

  getCorporateBulkOrders(): Observable<any>{
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/corporateOrder', this.httpOptions);
  }

  updateCorporateOrderComment(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/corporateOrder/updateComment', data, this.httpOptions);
  }
  
  fetchXsipTransactions(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/user/bse/manual-xsip-nav-upload', data, this.httpOptions);
  }

  exportAllTrnxData(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download-transaction', data, this.httpOptions);
  }

  exportFinxpertPurchaseData(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/qwikcilver/download-qwickCilver-transaction', data, this.httpOptions);
  }

  exportcorporateBulkOrderData(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download-corporateOrder', data, this.httpOptions);
  }

  exportManualKycData(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download-manualKyc-user', data, this.httpOptions);
  }

  exportAllPlanExpiredUsers(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download-expired-users', data, this.httpOptions);
  }

  getPhysicalCardDetails(data): Observable<any>{
    this.setHeaders();
    return this.http.get(API_URL + '/qwikcilver/getQwikCilver/physicalCard/'+data, this.httpOptions);
  }

  postPhysicalCardDetails(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/qwikcilver/save-update/qwikCilver/physicalCard', data, this.httpOptions);
  }
  
  exportAllSubscriptionEndingUsers(): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download-subscription-ending-users', null, this.httpOptions);
  }

  //billdesk si
  getAllBilldeskSiInvoices(): Observable<any>{
    this.setHeaders()
    return this.http.get(API_URL + '/backend/private/getAll-invoice-si', this.httpOptions);
  }
  generateBilldeskSiInvoices(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/generate-billdesk-invoice-si', data, this.httpOptions);
  }
  approveBilldeskSiInvoice(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/approve-billdesk-invoice-si', data, this.httpOptions);
  }
  downloadBilldeskSiInvoice(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/user/private/download-user-monthly-invoice', data, this.httpOptions);
  }
  subscriptionFilter(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/filterSubscribers', data, this.httpOptions);
  }

  //user list by partners
  getUsersByPartnerId(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/partnerCode/users', data, this.httpOptions);
  }

  //partner user list export
  exportPartnerUsersList(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download/partnerCode/users', data, this.httpOptions);
  }

  //update referral offer given to the user
  updateSentReferalOffer(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/referral-list/assign-offer', data, this.httpOptions);
  }

  //partner get promo code 
  getPartnerOfferCodes(partnerCode): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/partnerOffers/'+partnerCode, this.httpOptions);
  }

  //partner offer unique code generate
  generatePartnerOfferUniqueCode(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/generate-partnerUniqueId', data, this.httpOptions);
  }
  
  exportFreeUsersList(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download-free-user', data, this.httpOptions);
  }

  //generate bulk offer subcodes
  generateBulkOffersByOfferId(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/generate-bulkCouponCode', data, this.httpOptions);
  }

  exportBulkOfferCodes(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download-bulk-couponcodes', data, this.httpOptions);
  }

  //ecs
  saveECSData(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/save-ecs', data, this.httpOptions);
  }

  //get-user-consultation list
  getUserConsultationList(data): Observable<any>{
    this.setHeaders();
    return this.http.get(API_URL+ '/backend/private/get-consultation-list/'+data, this.httpOptions);
  }
  
  //update user consultation
  updateUserConsultation(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/update-consultation-review', data, this.httpOptions);
  }

  downloadReviewInvoice(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/user/private/download-appointment-confirmation-invoice', data, this.httpOptions);
  }

  dashboardUserCount(): Observable<any>{
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/user-count', this.httpOptions);
  }

  generateUccCode(data): Observable<any>{
    this.setHeaders();
    return this.http.post(API_URL + '/user/private/complete-registration?emailId='+data.email, null, this.httpOptions);
  }
  
  dashboardAUMData(): Observable<any>{
    this.setHeaders();
    return this.http.get(API_URL + '/backend/private/aumData', this.httpOptions);
  }
  
  //download LOE PDF
  getLOEData(ucc): Observable<any> {
    this.setHeaders();
    return this.http.get(API_URL +'/user/private/get-loe-file/'+ucc, this.httpOptions);
  }
  
  //marketing leads
  getMarketingLeads(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/marketing-leads-list?page&size&sort&order=DESENDING', data, this.httpOptions);
  }

  //export/download marketing leads
  exportMarketingLeads(data): Observable<any> {
    this.setHeaders();
    return this.http.post(API_URL + '/backend/private/download-marketing-leads', data, this.httpOptions);
  }

  async sendPushNotification(tokens, notificationData) {
    try {
      let data:any = {
        registration_ids: tokens,
        notification: {
          title: notificationData.title,
          body: notificationData.message,
          image: notificationData.imageUrl,
          click_action: "FCM_PLUGIN_ACTIVITY"
        },
        data: {
          title: notificationData.title,
          message: notificationData.message,
          imageUrl: notificationData.imageUrl,
          topic: notificationData.topic,
        }
      }
      console.log("notification data",data)
      const fcmRes = await this.http.post("https://fcm.googleapis.com/fcm/send", JSON.stringify(data)).toPromise()
      const saveRes = await this.saveNotifications(data.data)
      return { fcmRes: fcmRes, saveRes: saveRes }
    } catch (err) {
      throw Error(err)
    }
  }

  getAllFcmTokens(): Observable<any> {
    this.setHeaders()
    return this.http.get(`${API_URL}/fcm-backend/private/fcm-token-list`,this.httpOptions);
  }

  async saveNotifications(data) {
    try {
      this.setHeaders()
      const res = await this.http.post(`${API_URL}/fcm-backend/private/save-message`, JSON.stringify(data), this.httpOptions).toPromise()
      return res
    } catch (err) {
      throw Error(err)
    }
  }

  getAllNotifications(): Observable<any> {
    this.setHeaders()
    return this.http.get(`${API_URL}/fcm-user/private/get-all`,this.httpOptions);
  }

  getAllContacts(page,size,sort?,order?): Observable<any> {
    this.setHeaders()
    return this.http.get(`${API_URL}/backend/private/contact-info/get-all`,this.httpOptions);
    // return this.http.get(`${API_URL}/backend/private/contact-info/get-all?page=${page}&size=${size}&sort&order`,this.httpOptions);
  }

  checkRecaptchaResponse(data): Observable<any> {
    return this.http.post(API_URL + "/user/public/captcha-verify?response="+data, null);
  }

}
