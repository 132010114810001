// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  // baseUrl: 'http://192.168.2.17:8080',
  // // dev

//   baseUrl: 'http://mwjapi.mywealthjunction.com',
  // baseUrl: 'https://api-dev.jofin.ai',
  baseUrl: 'https://api.jofin.ai',
  
  // stag
  // baseUrl: 'http://192.168.2.95:8080'

  // prod
  // baseUrl: 'https://api.mywealthjunction.com' 
  firebaseFCMServerKey: "AAAAnZd9rBk:APA91bFROhf64otacBWyv_RvtKxmzh5hZl-HrbvUjjYlZdPYk8b-PoeMaNpDp0hqffhKPF-0g8jtor7fPsm9uWg3GDHVDaj3YmlNFPtH3UVSRis1WpnhVOEUMWClqmlzXCsWFtrG42UH",
  recaptcha: {
    siteKey: '6LeCnT0kAAAAAPLe3OkSUywnUb2baPM5gMHEXkIP',
  }

};
export const palete = {
  primary: '#D32F2F',
  accent: '#E65100',
  warm: '#C2185B',
  name: '#D50000',
  secondary: '#D81B60',
  tertiary: '#8E24AA',
  quaternary: '#5E35B1',
  quinary: '#3949AB',
  secondaryLight: '#E91E63',
  tertiaryLight: '#9C27B0',
  quaternaryLight: '#673AB7',
  quinaryLight: '#3F51B5'
}