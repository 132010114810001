import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LazyLoadModule } from './lazy-load/lazy-load.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
// import { HttpClientModule } from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptService } from './intercept.service';
import { HttpModule } from '@angular/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ExotelDialogComponent } from './exotel-dialog/exotel-dialog.component';
import { ReCaptchaModule } from 'angular-recaptcha3';

@NgModule({
  declarations: [
    AppComponent,
    ExotelDialogComponent,
  ],
  imports: [
    BrowserModule,
    LazyLoadModule,
    CoreModule,
    HttpModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AngularEditorModule,
    ReCaptchaModule.forRoot({
      invisible: {
          sitekey: '6LeCnT0kAAAAAPLe3OkSUywnUb2baPM5gMHEXkIP', 
      },
      normal: {
          sitekey: '6LeCnT0kAAAAAPLe3OkSUywnUb2baPM5gMHEXkIP', 
      },
      language: 'en'
    }),
  ],
  entryComponents: [
    ExotelDialogComponent
  ],
  providers: [HttpClientModule, HttpModule, 
     {
        provide: HTTP_INTERCEPTORS,
        useClass: InterceptService,
        multi: true
      }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
